//---------------------
//  Checkout Success  
//---------------------

// PACKAGES
import React from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"

// COMPONENTS
import Layout from "../layout/ThankYouLayout"
import MenuBack from "../assets/icons/menu-back.svg"

// COMPONENT BUILD
class ThankYou extends React.Component {
    
    // JSX TEMPLATE
    render() {
        return (
            <>
            <Helmet>
                <meta name="robots" content="noindex"/>
                <noscript>Please eneable Javascript or use a modern web browser!</noscript>
            </Helmet>
            <Layout>
                <section id="pricing">
                <div className="vh-100 col-12 container d-flex flex-column justify-content-center align-items-center text-center px-4 mb-n11">
                    <div className="align-items-center">
                        <h2 className="text-center underline mb-6">
                            Your order has been successful!
                        </h2>
                        <p className="text-center lead lead-small text-muted font-italic mb-8">
                            We will send you an email shortly with the following steps. <br/>Keep an eye on your email address you used during the checkout.<span role="img" aria-label="Thrilled emoji" style={{fontSize:`40px`, verticalAlign: `middle`, lineHeight: `1em`}}>✉️ 👀</span>{" "}
                        </p>
                        <p className="text-center lead lead-small text-muted font-italic mb-8"><small>
                            If you don't receive the email within a few minutes or experiencing any issues contact us at – contact@informeasy.com
                        </small></p>
                        <Link className="py-6 nav-link menu-link text-ie-muted-link navbar-light mr-md-5 py-md-2"
                        to="/"
                    >
                        <img className="pb-1 mr-3 d-inline-block position-relative" src={MenuBack} alt="Arrow-icon"/>
                        Back to homepage
                    </Link>
                    </div>
                </div>
            </section>
            </Layout>
            </>
        )
    }
}

export default ThankYou
